import styled, { keyframes } from 'styled-components';

import bannerContacts from '../../assets/banner-contacts.png';

const appearFromLeft = keyframes`
from{
opacity:0;
transform:translateX(-100px)
}
to{
  opacity:1;
transform:translateX(0)
}
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;
export const Container = styled.section`
  margin-top: 45px;
  height: 450px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url(${bannerContacts}) no-repeat;
  background-size: cover;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 513px;
    color: #540b0e;
    h1 {
      font-weight: bold;
      font-size: 80px;
    }
    p {
      font-size: 20px;
      text-align: center;
    }
  }
`;
