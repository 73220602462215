import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface IBurgerProps {
  open: boolean;
  setOpen(open: boolean): void;
}

const RightNav: React.FC<IBurgerProps> = ({ open, setOpen }) => {
  return (
    <Container open={open}>
      <Link to="/">
        <li onClick={() => setOpen(!open)}>HOME</li>
      </Link>

      <Link to="/about">
        <li onClick={() => setOpen(!open)}>SOBRE NÓS</li>
      </Link>

      <Link to="/projects">
        <li onClick={() => setOpen(!open)}>SERVIÇOS</li>
      </Link>

      <Link to="/partners">
        <li onClick={() => setOpen(!open)}>CONDOMÍNIOS</li>
      </Link>

      <Link to="/contacts">
        <li onClick={() => setOpen(!open)}>CONTATO</li>
      </Link>
    </Container>
  );
};

export default RightNav;
