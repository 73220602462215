import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f6f6;
  height: 745px;
  margin-top: 45px;
  padding: 15px 50px;
`;

export const RightInfo = styled.div``;

export const LeftInfo = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  width: 485px;
  height: 520px;
  font-weight: bold;
  h1 {
    font-size: 40px;
  }
  p {
    font-size: 20px;
  }
`;

export const TextDisplay = styled.div`
  width: 175px;
  height: 70px;
  font-size: 28px;
  background-color: #3d3d3d;
  color: #dcdcf9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 35px;
  border-radius: 4px;
`;

export const ContactCard = styled.div`
  width: 675px;
  height: 200px;
  background-color: #fafafa;

  font-size: 20px;
  font-family: Kanit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 60px;
  img {
    width: 150px;
    height: 150px;
    box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.35);
  }
  div {
    margin-left: 10px;
    a{
      text-decoration: none;
      color:#3d3d3d;
    }
    }
  }
`;
