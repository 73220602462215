import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 45px;
  height: 450px;
  background: rgba(196, 196, 196, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  stroke: 1px solid #e2e0e0;

  @media (max-width: 1000px) {
    padding: 0 20px;
  }

  @media (max-width: 700px) {
    padding: 5px;
    height: 300px;
  }
`;

export const CardContainer = styled.div`
  width: 350px;
  height: 450px;
  stroke: solid #e2e0e0;
  border-radius: 8px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.35);

  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;

  header {
    cursor: pointer;
    img {
      width: 100px;
      height: 90px;
      transition: transform 0.5s;

      &:hover {
        transform: scale(1.3, 1.3);
      }
    }
    svg {
      color: #540b0e;
    }
  }

  h1 {
    color: #3d3b8e;
    font-size: 50px;
    font-weight: bold;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: #540b0e;
  }

  @media (max-width: 1000px) {
    width: 300px;
    height: 300px;
    h1 {
      font-size: 30px;
    }
  }

  @media (max-width: 800px) {
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;
