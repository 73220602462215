import React from 'react';
import { FaMobileAlt, FaMailBulk } from 'react-icons/fa';

import {
  Container,
  Foot,
  Fone,
  SectionLeft,
  SectionRight,
  Mail,
} from './styles';

const Footer: React.FC = () => (
  <>
    <Container>
      <SectionLeft>
        <div>
          <h1>Faça um orçamento</h1>
          <p>
            Ofereça aos seus condominos a tranquilidade da RF Sindico
            Profissional
          </p>
        </div>

        <Fone>
          <FaMobileAlt color="#540B0E" size={48} />
          <div>
            <span>(21) 988074564</span>
            <span>(21) 994412635</span>
            <span>(21) 993534432</span>
            <span>(21) 985112585</span>
          </div>
        </Fone>

        <Mail>
          <FaMailBulk color="#540B0E" size={48} />
          <div>
            <span>
              <a href="mailto:luciano.santos@rfsindicoprofissional.com.br">
                luciano.santos@rfsindicoprofissional.com.br
              </a>
            </span>
            <span>
              <a href="mailto:robson.nascimento@rfsindicoprofissional.com.br">
                robson.nascimento@rfsindicoprofissional.com.br
              </a>
            </span>
            <span>
              <a href="mailto:gleison.carvalho@rfsindicoprofissional.com.br">
                gleison.carvalho@rfsindicoprofissional.com.br
              </a>
            </span>
            <span>
              <a href="mailto:wellington.silva@rfsindicoprofissional.com.br">
                wellington.silva@rfsindicoprofissional.com.br
              </a>
            </span>
          </div>
        </Mail>
      </SectionLeft>
      <SectionRight>
        <div>
          <label htmlFor="name">Nome(obrigatório)</label>
          <input type="text" name="name" />
          <label htmlFor="email">E-mail(obrigatório)</label>
          <input type="email" name="email" />
          <label htmlFor="phone">DDD Telefone</label>
          <input type="text" name="phone" />
          <label htmlFor="message">Mensagem(obrigatório)</label>
          <textarea name="message" />

          <button>Solicitar Proposta</button>
        </div>
      </SectionRight>
    </Container>
    <Foot>
      <strong>© 2020 - Todos os direitos reservados</strong>
    </Foot>
  </>
);

export default Footer;
