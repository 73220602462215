import React from 'react';

import Banner from '../../components/BannerContacts';

import foneIcon from '../../assets/phone.svg';
import mailIcon from '../../assets/email-icon.png';

import {
  Container,
  TextDisplay,
  ContactCard,
  CardContent,
  LeftInfo,
  RightInfo,
} from './styles';

const Contacts: React.FC = () => {
  return (
    <>
      <Banner />
      <Container>
        <RightInfo>
          <TextDisplay>Telefones</TextDisplay>
          <ContactCard>
            <CardContent>
              <img src={foneIcon} alt="Fone Contacts" />
              <div>
                <p>
                  <strong>(21) 988074564 Luciano Santos</strong>
                </p>
                <p>
                  <strong>(21) 994412635 Robson Nascimento</strong>
                </p>
                <p>
                  <strong>(21) 993534432 Gleison Carvalho</strong>
                </p>
                <p>
                  <strong>(21) 985112585 Wellington Silva</strong>
                </p>
              </div>
            </CardContent>
          </ContactCard>
          <TextDisplay>E-mails</TextDisplay>
          <ContactCard>
            <CardContent>
              <img src={mailIcon} alt="E-mail" />
              <div>
                <p>
                  <a href="mailto:luciano.santos@rfsindicoprofissional.com.br">
                    luciano.santos@rfsindicoprofissional.com.br
                  </a>
                </p>
                <p>
                  <a href="mailto:robson.nascimento@rfsindicoprofissional.com.br">
                    robson.nascimento@rfsindicoprofissional.com.br
                  </a>
                </p>
                <p>
                  <a href="mailto:gleison.carvalho@rfsindicoprofissional.com.br">
                    gleison.carvalho@rfsindicoprofissional.com.br
                  </a>
                </p>
                <p>
                  <a href="mailto:wellington.silva@rfsindicoprofissional.com.br">
                    wellington.silva@rfsindicoprofissional.com.br
                  </a>
                </p>
              </div>
            </CardContent>
          </ContactCard>
        </RightInfo>
        <LeftInfo>
          <h1>RF Sindico Profissional</h1>
          <p>1) Experiência;</p>
          <p>2) Sabemos resolver conflitos;</p>
          <p>3) Dispomos de parcerias com corpo jurídico competente;</p>
          <p>4) Combatemos desperdícios;</p>
          <p>5) Contamos com profissionais parceiro</p>
          <p>6) Atendimento pontual e determinado;</p>
          <p>7) Buscamos a valorização permanente do seu imóvel;</p>
          <p>
            8) Atuamos desde os detalhes mais simples de limpeza e manutenção
            até os mais complexos de segurança e inadimplência;
          </p>
          <p>9) Inovamos constantemente na área condominial;</p>
          <p>10) Sustentabilidade é uma de nossas metas.</p>
        </LeftInfo>
      </Container>
    </>
  );
};

export default Contacts;
