import React, { useState } from 'react';

import logoImg from '../../assets/logo_2.png';

import { Container, LogoMarca } from './styles';

import Burger from './Burger';

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <LogoMarca className="logo">
        <img src={logoImg} alt="Rf Sindico Profissional" />
        <strong>RF</strong>
        <p>Síndico Profissional</p>
      </LogoMarca>
      <Burger open={open} setOpen={setOpen} />
    </Container>
  );
};

export default Header;
