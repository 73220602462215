import React from 'react';

import BannerHome from '../../components/BannerHome';
import SectionCards from '../../components/SectionCards';
import BannerProjects from '../../components/BannerProjects';

import { Container } from /* , Footer */ './styles';

const Home: React.FC = () => (
  <Container>
    <BannerHome />
    <SectionCards />
    <BannerProjects />
  </Container>
);

export default Home;
