import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{
  margin:0;
  padding:0;
  outline:0;
  box-sizing:border-box;

}
body{
  background:#f0f0f5;
  -webkit-font-smoothing:antialiased !important;
}
body, input, button{
  font: 12px Kanit, sans-serif;
}

#root{
flex:1;
width:100%;
font-size: 14px;
@media (max-width: 768px) {
  width:231%;
  }

}

button{
  cursor:pointer;
}
`;
