import React from 'react';

import Banner from '../../components/BannerAbout';

import buildings from '../../assets/buildings.jpg';
import hands from '../../assets/hands.jpg';
import table from '../../assets/table.jpg';

import { Container, Section, TextArea, ImageArea, Image } from './styles';

const About: React.FC = () => {
  return (
    <Container>
      <Banner />
      <Section>
        <div className="content">
          <div className="section-content">
            <TextArea>
              <header>A Idéia</header>
              <p>
                A RF Síndico profissional, é uma empresa de gestão e assessoria
                para condomínios residenciais e comerciais. Formada com quem
                mais entende do assunto, e com experiência de mais de 20 anos em
                Administração em Condomínios. Com formação acadêmica em;
                Direito, Ciências contábeis, Gestão Condominial e RH. Nosso
                objetivo é atender com excelência às necessidades do Condomínio,
                gerando valorização do seu patrimônio, desenvolvendo serviços
                que contribuam para a melhoria da qualidade de vida dos
                Condôminos, bem como buscando a redução de custos e procurando
                sempre conhecimento e inovação.
              </p>
            </TextArea>
            <ImageArea>
              <Image src={buildings} alt="Buildins" />
            </ImageArea>
          </div>
        </div>
        <div className="content">
          <div className="section-content">
            <ImageArea>
              <Image src={hands} alt="Hands" />
            </ImageArea>
            <TextArea>
              <header>A Visão</header>
              <p>
                Nossa visão de futuro é, cada dia torna-se melhor, ser uma
                empresa reconhecida principalmente pelo índice de satisfação de
                nossos clientes, atuando com respeito a nossos princípios,
                valores éticos e morais.
                <br />
                Desenvolvendo e liderando ações que fomentem a harmonia entre os
                condôminos, assegurando que os Direitos e Deveres descritos na
                Convenção e no Regimento Interno sejam cumpridos.
              </p>
            </TextArea>
          </div>
        </div>
        <div className="content">
          <div className="section-content">
            <TextArea>
              <header>O Compromisso</header>
              <p>
                Realizar um estudo e um bom planejamento financeiro com o
                objetivo de eliminar os desperdícios e reduzir os custos fixos;
                identificar e corrigir aspectos internos e externos que possam
                influenciar negativamente nas ações da coletividade; divulgar os
                resultados alcançados periodicamente.
              </p>
            </TextArea>
            <ImageArea>
              <Image src={table} alt="Tale" />
            </ImageArea>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default About;
