import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 105px;
  border-bottom: 1px solid;
  padding: 0 100px;
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  background: #3d3b8e;
`;

export const LogoMarca = styled.div`
  width: 195px;
  padding: 15px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #dcdcf9;

  img {
    height: 55px;
    width: 55px;
  }

  strong {
    margin-top: 2px;
  }
`;
