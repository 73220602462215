import React from 'react';
import { Link } from 'react-router-dom';

import { Container, AnimationContainer, Button } from './styles';

const BannerProjects: React.FC = () => (
  <>
    <Container>
      <AnimationContainer>
        <div>
          <h1>Modelo de gestão inteligente</h1>
          <p>
            Nossos profissionais especializados oferencem uma estrutura sólida
            de gerenciamento aos nossos parceiros
          </p>
        </div>
        <Link to="/projects">
          <Button>Saiba mais</Button>
        </Link>
      </AnimationContainer>
    </Container>
  </>
);
export default BannerProjects;
