import styled from 'styled-components';

export const Container = styled.div`
  width: 200px;
  height: 50px;
  background-color: #3d3d3d;
  color: #dcdcf9;
  font-size: 18;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 100px 20px 100px;
  border-radius: 4px;
`;
