import React from 'react';
import RightNav from '../RightNav';

import { Container } from './styles';

interface IBurgerProps {
  open: boolean;
  setOpen(open: boolean): void;
}

const Burger: React.FC<IBurgerProps> = ({ open, setOpen }) => {
  return (
    <>
      <Container open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </Container>
      <RightNav open={open} setOpen={setOpen} />
    </>
  );
};

export default Burger;
