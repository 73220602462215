import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import Projects from '../pages/Projects';
import Partners from '../pages/Partners';
import Contacts from '../pages/Contacts';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/contacts" component={Contacts} />
      <Route path="/about" component={About} />
      <Route path="/partners" component={Partners} />
      <Route path="/projects" component={Projects} />
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default Routes;
