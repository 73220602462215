import React from 'react';

import { Container, AnimationContainer } from './styles';

const BannerHome: React.FC = () => (
  <>
    <Container>
      <AnimationContainer>
        <div>
          <h1>Condomínios</h1>
          <p>Conheça alguns de nossos parceiros</p>
        </div>
      </AnimationContainer>
    </Container>
  </>
);

export default BannerHome;
