import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/about.svg';
import projectsImg from '../../assets/projects.svg';
import solutionImg from '../../assets/solution.svg';

import { Container, CardContainer } from './styles';

const SectionCards: React.FC = () => (
  <Container>
    <CardContainer>
      <header>
        <Link to="/about">
          <img src={aboutImg} alt="About us" />
        </Link>
      </header>
      <h1>Sobre Nós</h1>
      <div>
        <p>
          A RF Síndico profissional é uma empresa que busca atuar com Ética e
          Justiça valorizando e respeitando o ser humano, buscando dia a dia
          realizar seu trabalho por meio da energia gerada de seus colaboradores
          enxergando o próximo como seu igual semelhante...
        </p>
      </div>
    </CardContainer>

    <CardContainer>
      <header>
        <Link to="/projects">
          <img src={projectsImg} alt="Projects" />
        </Link>
      </header>
      <h1>Serviços</h1>
      <div>
        <p>
          Ao realizar um estudo e um bom planejamento financeiro com o objetivo
          de eliminar os desperdícios e reduzir os custos fixos; identificar e
          corrigir aspectos internos e externos que possam influenciar
          negativamente nas ações da coletividade; divulgar os resultados
          alcançados periodicamente...
        </p>
      </div>
    </CardContainer>

    <CardContainer>
      <header>
        <Link to="/contacts">
          <img src={solutionImg} alt="Solutions" />
        </Link>
      </header>
      <h1>Soluções</h1>
      <div>
        <p>
          As demandas do condomínio são muitas, e a cada dia elas aumentam. Seja
          qual for o porte do condomínio, é necessário ter um síndico que zele
          pela ordem física do lugar e também dos pagamentos a serem realizados,
          resolver problemas, tudo dentro da Lei...
        </p>
      </div>
    </CardContainer>
  </Container>
);

export default SectionCards;
