import React from 'react';

import { Container, AnimationContainer } from './styles';

const BannerHome: React.FC = () => (
  <>
    <Container>
      <AnimationContainer>
        <div>
          <h1>Contato</h1>
          <p>Entre em contato, solicite uma proposta.</p>
        </div>
      </AnimationContainer>
    </Container>
  </>
);

export default BannerHome;
