import React from 'react';

import { Container, AnimationContainer } from './styles';

const BannerHome: React.FC = () => (
  <>
    <Container>
      <AnimationContainer>
        <div>
          <h1>Sobre nós</h1>
          <p>
            Conheça quem esta por traz da RF Sindico Profissional dando o melhor
            de si por nossos parceiros.
          </p>
        </div>
      </AnimationContainer>
    </Container>
  </>
);

export default BannerHome;
