import styled from 'styled-components';
import { shade } from 'polished';

import bannerHome from '../../assets/banner-footer.jpg';

export const Container = styled.div`
  margin-top: 45px;
  height: 450px;
  padding: 30px 100px;
  color: #540b0e;
  display: flex;
  justify-content: space-between;

  background: url(${bannerHome}) no-repeat;
  background-size: cover;
`;

export const SectionLeft = styled.div`
  h1 {
    font-weight: bold;
    font-size: 36px;
  }
  p {
    width: 470px;
    font-size: 18px;
  }
`;
export const SectionRight = styled.div`
  width: 281px;
  height: 372px;
  background: #fff;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  stroke: 1px solid #000;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 15px 30px;

    input {
      border: 0;
      border-bottom: 1px solid;
      margin-bottom: 4px;
      padding-left: 5px;
    }
    textarea {
      height: 145px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 8px;
    }

    button {
      position: absolute;
      width: 237px;
      height: 50px;
      top: 340px;
      left: 25px;
      background: #3d3b8e;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      color: #fff;
      border: 0;
      transition: background 0.4s;

      &:hover {
        background: ${shade(0.4, '#3d3b8e')};
      }
    }
  }
`;

export const Foot = styled.div`
  margin-top: 45px;
  height: 87px;
  background: #3d3b8e;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 100px 10px 0;
  color: #dcdcf9;
`;

export const Fone = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;

  span {
    transition: font-size 0.3s;
    &:hover {
      cursor: pointer;
      font-size: 16px;
    }
  }

  svg {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
`;
export const Mail = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  a {
    text-decoration: none;
    color: #000;
    transition: font-size 0.3s;

    &:hover {
      font-size: 16px;
    }
  }

  svg {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
  div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
`;
