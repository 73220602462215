import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Section = styled.section`
  margin-top: 45px;

  .content {
    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
    }
  }

  .section-content {
    height: 320px;
    padding: 0 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 900px) {
      padding: 0 30px;
    }

    margin-bottom: 40px;
  }
`;

export const TextArea = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    font-size: 26px;
    margin-bottom: 20px;
  }
  p {
    text-align: justify;
  }
  @media (max-width: 900px) {
    width: 355px;
    height: 320px;
  }
`;

export const ImageArea = styled.div`
  width: 500px;

  @media (max-width: 900px) {
    width: 355px;
    height: 320px;
  }
`;

export const Image = styled.img`
  width: 555px;
  height: 320px;
  @media (max-width: 900px) {
    width: 355px;
    height: 320px;
  }
`;
