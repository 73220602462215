import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 50px;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  background-color: #dcdcf9;
`;
export const TextArea = styled.div`
  padding: 10px 20px;
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 50px;
  }
  div {
    width: 750px;
    p {
      text-align: center;
      margin-bottom: 15px;
    }
  }
`;

export const TextDisplay = styled.div`
  width: 585px;
  height: 70px;
  font-size: 28px;
  background-color: #3d3d3d;
  color: #dcdcf9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 35px;
`;

export const ImageArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;
