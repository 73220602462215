/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import BannerPartners from '../../components/BannerPartners';
import condomínio from '../../assets/condominio.png';

import { Container, TextArea, TextDisplay, ImageArea } from './styles';

const Partners: React.FC = () => {
  return (
    <Container>
      <BannerPartners />
      <TextArea>
        <p>Alguns de nossos parceiros</p>
        <h1>Rio de Janeiro</h1>
        <div>
          <p>
            Atuamos na em todo estado do Rio e Janeiro, oferecendo um serviço
            especializado, nossa comunicação com nossos parceiros é direcionada
            e focada.
          </p>
          <p>
            Alguns dos processos que cuidamos, garantindo a tranquilidade dos
            nossos parceiros sao: Procedimentos Administrativos, Gestão de
            Fornecedores e Procedimentos de Segurança.
          </p>
        </div>
      </TextArea>

      <TextDisplay>Condomínios Residênciais e Comerciais</TextDisplay>

      <ImageArea>
        <div>
          <img src={condomínio} alt="Condominios" />
          <p>
            <strong>Condomínio Luz do Luar</strong> - Niterói
          </p>
        </div>
        <div>
          <img src={condomínio} alt="Condominios" />
          <p>
            <strong>Condomínio Sal da Terra</strong> - Barra Tijuca
          </p>
        </div>
      </ImageArea>
      <ImageArea>
        <div>
          <img src={condomínio} alt="Condominios" />
          <p>
            <strong>Condomínio Sonho de Verão</strong> - Rio de Janeiro
          </p>
        </div>
      </ImageArea>
    </Container>
  );
};

export default Partners;
