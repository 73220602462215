import styled from 'styled-components';
import { shade } from 'polished';

interface IBurgerProps {
  open: boolean;
}

export const Container = styled.div<IBurgerProps>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  color: #dcdcf9;
  a {
    text-decoration: none;
    color: #dcdcf9;
    transition: color 0.3s ease;
    &:hover {
      color: ${shade(0.3, '#dcdcf9')};
    }
  }

  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #3d3b8e;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding-top: 3rem;
    transition: transform 0.3s ease-in-out;
  }
`;
