import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Section = styled.section`
  margin-top: 45px;

  .section-content {
    padding: 0 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3d3d3d;
    margin-bottom: 45px;
    div {
      width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      }
    }
  }
`;

export const TextArea = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageArea = styled.div``;

export const Image = styled.img`
  width: 500px;
  height: 450px;
`;
