import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Button, AnimationContainer } from './styles';

const BannerHome: React.FC = () => (
  <>
    <Container>
      <AnimationContainer>
        <div>
          <h1>Fale com nossos profissionais</h1>
          <p>Podemos ajudá-lo, a solução esta a um clique</p>
        </div>
        <Link to="/contacts">
          <Button>Entre em contato</Button>
        </Link>
      </AnimationContainer>
    </Container>
  </>
);

export default BannerHome;
