import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import bannerHome from '../../assets/banner-home.jpg';

const appearFromLeft = keyframes`
from{
opacity:0;
transform:translateX(-100px)
}
to{
  opacity:1;
transform:translateX(0)
}
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;
export const Container = styled.section`
  margin-top: 45px;
  height: 450px;
  padding: 150px 0 0 100px;

  background: url(${bannerHome}) no-repeat;
  background-size: cover;

  div {
    color: #540b0e;
    h1 {
      font-weight: bold;
      font-size: 36px;
    }
    p {
      width: 470px;
      font-size: 18px;
    }
  }
`;
export const Button = styled.button`
  margin-top: 16px;
  border-radius: 10px;
  background: #3d3b8e;
  width: 145px;
  height: 40px;
  font-size: 16px;
  color: #dcdcf9;
  transition: background 0.3s;
  border: 0;

  &:hover {
    background: ${shade(0.3, '#3d3b8e')};
  }
`;
