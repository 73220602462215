import React from 'react';

import Banner from '../../components/BannerServices';
import TextDisplay from '../../components/TextDisplay';

import buildings from '../../assets/gestao.png';
import hands from '../../assets/profissional.png';
import table from '../../assets/solution.png';

import { Container, Section, TextArea, ImageArea, Image } from './styles';

const About: React.FC = () => {
  return (
    <Container>
      <Banner />
      <Section>
        <div className="section-header">
          <div>
            <header>Nossos Serviços</header>
            <p>Atendemos condomínios em todo estado do Rio de Janeiro</p>
          </div>
        </div>

        <div className="content">
          <TextDisplay>Gestão Especializada</TextDisplay>
          <div className="section-content">
            <ImageArea>
              <Image src={buildings} alt="Buildings" />
            </ImageArea>
            <TextArea>
              <p>
                "A RF Síndico profissional, é uma empresa de gestão e assessoria
                para condomínios residenciais e comerciais. Formada com quem
                mais entende do assunto, e com experiência de mais de 20 anos em
                Administração em Condomínios. Com formação acadêmica em;
                Direito, Ciências contábeis, Gestão Condominial e RH. Nosso
                objetivo é atender com excelência às necessidades do Condomínio,
                gerando valorização do seu patrimônio, desenvolvendo serviços
                que contribuam para a melhoria da qualidade de vida dos
                Condôminos, bem como buscando a redução de custos e procurando
                sempre conhecimento e inovação."
              </p>
            </TextArea>
          </div>
        </div>
        <div className="content">
          <TextDisplay>O Sindico Profissional</TextDisplay>
          <div className="section-content">
            <TextArea>
              <p>
                "As demandas do condomínio são muitas, e a cada dia elas
                aumentam. Seja qual for o porte do condomínio, é necessário ter
                um síndico que zele pela ordem física do lugar e também dos
                pagamentos a serem realizados, resolver problemas, tudo dentro
                da Lei. Mas ser síndico é uma tarefa que se torna mais difícil a
                cada dia, principalmente para os condomínios com maior
                quantidade de apartamentos e área. Portanto conte conosco para
                gerir seu Condomínio, deixando que nós resolvemos os problemas
                enfrentados dia a dia na gestão condominial. "
              </p>
            </TextArea>
            <ImageArea>
              <Image src={hands} alt="Hands" />
            </ImageArea>
          </div>
        </div>
        <div className="content">
          <TextDisplay>Nossas Soluções</TextDisplay>
          <div className="section-content">
            <ImageArea>
              <Image src={table} alt="Tale" />
            </ImageArea>
            <TextArea>
              <p>
                "Prestação de serviço ao Condomínio, de forma a racionalizar os
                trabalhos e aperfeiçoar o nível de atendimento em geral,
                atendimento à Convenção e ao Regimento Interno do Condomínio,
                observância e cumprimento às decisões de Assembleias,
                equacionamento e atendimento das necessidades / problemas do
                Condomínio, disciplina dos empregados para correto atendimento
                aos moradores, limpeza, manutenção e vigilância do Condomínio,
                contatos com Membros do Conselho Consultivo, para acompanhamento
                e tomada de decisões, prestação de contas ao Conselho Consultivo
                e aos Condôminos mensalmente, adequação das despesas, de acordo
                com os orçamentos aprovados."
              </p>
            </TextArea>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default About;
